import styled from 'styled-components'

interface SlideContentProps {
  color: string
}

export const SectionWrapper = styled.section`
  margin-top: 32px;

  .swiper-container {
    padding-bottom: 50px;

    @media screen and (min-width: 1024px) {
      padding-bottom: 0;
    }
  }

  .swiper-pagination {
    @media screen and (min-width: 1024px) {
      display: none;
    }
  }

  .swiper-pagination-bullet {
    width: 4px;
    height: 4px;
    transition: all .3s ease-in-out;

    @media screen and (min-width: 768px) {
      width: 6px;
      height: 6px;
    }
  }

  .swiper-pagination-bullet-active {
    width: 16px;
    border-radius: 4px;
    background-color: #1EA4F3;
    transition: all .3s ease-in-out;

    @media screen and (min-width: 768px) {
      border-radius: 6px;
    }
  }
`

export const SlideContent = styled.div<SlideContentProps>`
  display: flex;
  align-items: center;
  background-color: ${({ color }) => color};
  font-size: 28px;
  line-height: 38px;
  padding: 57px 48px;
  display: flex;
  height: 280px;

  @media screen and (min-width: 768px) {
    padding: 32px;
    font-size: 22px;
    line-height: 32px;
    min-height: 225px;
    height: inherit;
  }

  @media screen and (min-width: 1024px) {
    min-height: inherit;
  }

  @media screen and (min-width: 1280px) {
    height: 230px;
  }

  @media screen and (min-width: 1360px) {
    padding: 57px 81px;
    font-size: 28px;
    line-height: 38px;
    height: 230px;
  }

  @media screen and (min-width: 1920px) {
    padding: 57px 150px;
  }
`
