import React from 'react'
import SwiperCore, { Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.css';

import * as Styled from './styled'

SwiperCore.use([Pagination, A11y]);

const SectionFeature = (): JSX.Element => (
  <Styled.SectionWrapper>
    <Swiper
      spaceBetween={0}
      slidesPerView={1}
      pagination={{ clickable: true }}
      breakpoints={{
        768: {
          init: false,
          slidesPerView: 3,
        },
      }}
    >
      <SwiperSlide>
        <Styled.SlideContent color="#FFECF0">
          <strong>Separe gastos pessoais dos gastos da sua empresa.</strong>
        </Styled.SlideContent>
      </SwiperSlide>
      <SwiperSlide>
        <Styled.SlideContent color="#FFCFDA">
          <strong>Acompanhe e gerencie despesas de forma inteligente.</strong>
        </Styled.SlideContent>
      </SwiperSlide>
      <SwiperSlide>
        <Styled.SlideContent color="#FFB2C5">
          <strong>Melhore o controle financeiro e a gestão do fluxo de caixa.</strong>
        </Styled.SlideContent>
      </SwiperSlide>
    </Swiper>
  </Styled.SectionWrapper>
)

export default SectionFeature
