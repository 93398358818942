import React, { useState } from 'react'
import faq from '../data/faq-card'

import Layout from '../components/Layout'
import SEO from '../components/seo'

import {
  SectionHero,
  SectionBackground,
  SectionFeature,
  SectionAboutCard
} from '../partials/CardPage'

import {
  SectionFAQ,
  SectionStepsRegister,
  SectionStores,
  SideSection
} from '../partials/commons'

import getStaticImage from '../hooks/getStaticImage'

const CardPage = (): JSX.Element => {
  const [toggleForm, setToggleForm] = useState(false)

  const toggle = (): void => {
    setToggleForm(true)
  }

  const schemaImg = getStaticImage('cora-cartao-empresarial.png')
  const schema = {
    title: 'Cartão',
    images: [schemaImg],
    type: 'Product',
    description:
      'Cartão de crédito e débito pj da bandeira visa que é aceita em milhões de estabelecimentos comerciais. O cartão empresarial da Cora possui a tecnologia contactless que possibilita o pagamento por aproximação',
  }

  return (
    <Layout pageType="cartao-empresarial" toggle={toggle}>
      <SEO
        title="Cartão empresarial sem anuidade para quem empreende | Cora"
        description="O cartão PJ da sua conta Cora. Prático, moderno e sem anuidade. Um cartão para empresas, feito para quem empreende. Peça o seu!"
        schema={schema}
        linkCanonical="cartao-empresarial"
      />
      <SectionHero toggle={toggle} />
      <SectionBackground />
      <SectionFeature />
      <SectionAboutCard />
      <SectionStepsRegister />
      <SectionFAQ data={faq} />
      <SectionStores
        title="Cadastre sua empresa"
        text="Aproveite a conta PJ gratuita e fácil de abrir."
        linkText="Tão simples quanto baixar um app"
        pageType="cartao-empresarial"
      />
      <SideSection
        toggleForm={toggleForm}
        setToggleForm={setToggleForm}
        idFinishForm="FormFinishedCartao"
        pageType="cartao-empresarial"
      />
    </Layout>
  )
}

export default CardPage
