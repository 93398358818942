import styled from 'styled-components'

interface SlideContentProps {
  color: string
}

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 40px 0 32px;

  @media screen and (min-width: 768px) {
    grid-column: 1/-1;
  }

  @media screen and (min-width: 1280px) {
    grid-column: 2/11;
    padding: 215px 0 150px;
  }

  @media screen and (min-width: 1920px) {
    grid-column: 2/11;
    padding: 160px 0;
  }
`

export const HeadingWrapper = styled.div`
  padding: 60px 0;

  @media screen and (min-width: 375px) {
    padding: 40px 0;
  }

  @media screen and (min-width: 768px) {
    padding: 0;
  }
`

export const Text = styled.span`
  color: #FFF;
  display: flex;
  font-size: 16px;
  line-height: 24px;
  padding: 24px 0 32px;

  @media screen and (min-width: 768px) {
    font-size: 22px;
    line-height: 32px;
    margin-top: 8px;
  }
`
