import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

import Heading from '../../../components/Heading'

import * as Styled from './styled'

const SectionBackground = (): JSX.Element => {
  const { mobileImage, tabletImage, desktopImage, desktopLargeImage } = useStaticQuery(
    graphql`
      query {
        mobileImage: file(relativePath: { eq: "new/card/background-section-mobile.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 490, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        tabletImage: file(relativePath: { eq: "new/card/background-section-tablet.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        desktopImage: file(relativePath: { eq: "new/card/background-section-desktop.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        desktopLargeImage: file(relativePath: { eq: "new/card/background-section-desktop-large.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const sources = [
    mobileImage.childImageSharp.fluid,
    {
      ...tabletImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
    {
      ...desktopImage.childImageSharp.fluid,
      media: `(min-width: 1024px)`,
    },
    {
      ...desktopLargeImage.childImageSharp.fluid,
      media: `(min-width: 1920px)`,
    },
  ]

  return (
    <BackgroundImage
      Tag={`section`}
      fluid={sources}
    >
      <div className="container">
        <Styled.SectionContent>
          <Styled.HeadingWrapper>
            <Heading headingLevel="h2" size="xlarge" dark>É preciso coragem pra empreender — vá em frente e leve seu Cartão PJ Cora junto.</Heading>
          </Styled.HeadingWrapper>

          <Styled.Text>Ter uma conta PJ só para sua empresa torna a sua vida mais simples, com um cartão fica ainda melhor!</Styled.Text>
        </Styled.SectionContent>
      </div>
    </BackgroundImage>
  )
}

export default SectionBackground
