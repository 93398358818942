import styled from 'styled-components'

export const Section = styled.section`
  padding: 80px 0;

  @media screen and (min-width: 768px) {
    padding: 120px 0;
  }

  @media screen and (min-width: 1280px) {
    padding: 160px 0;
  }
`

export const HeadingWrapper = styled.div`
  grid-column: 2/12;
`

export const CardsWrapper = styled.div`
  grid-column: 2/12;
  margin-top: 48px;

  @media screen and (min-width: 1280px) {
    grid-column: 3/11;
    margin-top: 80px;
  }
`
