const faq = [
  {
    question: 'Quando vou receber o cartão?',
    answer: 'Depois da aprovação da sua conta, você solicita seu cartão no app em poucos cliques, ele é gerado e enviado para seu endereço. Ele chegará em até 10 dias úteis. Você pode acompanhar o andamento da entrega diretamente no aplicativo da Cora.',
    open: false,
  },
  {
    question: 'Como e onde posso usar meu cartão físico?',
    answer: 'Após a abertura e aprovação da conta Cora, você recebe gratuitamente um cartão físico, que já vem habilitado para compras no débito. A função crédito será liberada mediante análise. Seu cartão físico pode ser utilizado em todos os estabelecimentos nacionais e internacionais que aceitam a bandeira Visa. Você também pode utilizar seu cartão de débito para realizar saques em caixas eletrônicos da rede Banco24horas por uma tarifa de R$ 9,90 por saque realizado.',
    open: false,
  },
  {
    question: 'Qual a função do meu cartão virtual?',
    answer: 'O cartão virtual está disponível para quem tem a função crédito habilitada no cartão físico. Ele é utilizado na modalidade crédito e é indicado para compras online. Ideal pagar assinaturas recorrentes e serviços que pedem um cartão de crédito para contratação, como anúncios e softwares, nacionais e internacionais.',
    open: false,
  },
  {
    question: 'Posso parcelar compras no meu cartão virtual?',
    answer: 'Sim. No entanto, o parcelamento de compras só é permitido na função crédito do cartão Cora, na versão física ou virtual.',
    open: false,
  },
  {
    question: 'Como eu posso fazer um saque?',
    answer: 'Você pode fazer saques em qualquer caixa eletrônico do Banco 24H e redes credenciadas, utilizando seu cartão físico de débito. Será cobrada uma tarifa de saque, no valor de R$ 9,90.',
    open: false,
  },
  {
    question: 'O cartão Cora funciona para compras internacionais?',
    answer: 'Sim. O cartão virtual e o cartão físico Cora são aceitos em milhares de estabelecimentos em todo o mundo. Lembrando que o cartão físico vem com a função débito habilitada, e a função crédito é liberada mediante análise. Para realizar compras internacionais online, basta utilizar o seu cartão virtual na modalidade crédito e o valor da compra com impostos será debitado automaticamente do seu limite.',
    open: false,
  },
];

export default faq;
