import React from 'react'
import Img from "gatsby-image"

import Heading from '../../../components/Heading'
import Card from '../../../components/Card'

import * as Styled from './styled'
import getFluidImage from '../../../hooks/getFluidImage'
import { aboutInfos } from './data'

const SectionAboutCard = (): JSX.Element => {
  return (
    <Styled.Section>
      <div className="container">
        <Styled.HeadingWrapper>
          <Heading headingLevel="h2" size="xlarge" center>
            Um cartão empresarial para agilizar e facilitar seus negócios.
          </Heading>
        </Styled.HeadingWrapper>
        <Styled.CardsWrapper>
          {
            aboutInfos.map(({ title, description, image }: IAboutInfo, index: number) => (
              <Card key={`about-card-${index}`}>
                <div className="image-wrapper">
                  <div>
                    <Img fluid={getFluidImage(image)} />
                  </div>
                </div>
                <div className="box-description">
                  <h3 className="title">{title}</h3>
                  <p className="text">{description}</p>
                </div>
              </Card>
            ))
          }
        </Styled.CardsWrapper>
      </div>
    </Styled.Section>
  )
}

export default SectionAboutCard
